/*            */
/*   HEADER   */
/*            */

.header {
	display: flex;
	width: 100%;
	height: 60px;
	visibility: hidden;
	set-position(absolute, { top: 0, left: 0, z-index: $index.header });

	.top {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 20px;
		height: 60px;
		background-color: $white;
		text-transform: uppercase;
		set-font($gt_sectra_fine, 12px, 700, normal, $black);
		font-tracking(240);
	}

	.dealer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
		background-color: $cold_turkey_dark;
		pointer-events: auto;

		a {
			display: block;
			border-bottom: 2px solid $white;
			text-align: center;
			text-transform: uppercase;
			white-space: nowrap;
			set-font($montserrat, 11px, 700, 20px, $white);
			font-tracking(100);
		}
	}

	.back {
		display: none;
		width: 40px;
		height: 100px;
		pointer-events: auto;
		set-position(absolute, { top: 50%, right: 30px });
		set-transform($translate.center);

		&:after {
			content: "";
			set-position(absolute, { bottom: 0, left: 50% });
			set-transform($translate.center_x);
			set-sprite-clip(-10px, 0, 10px, 20px);
		}

		a {
			display: block;
			text-align: left;
			text-transform: uppercase;
			white-space: nowrap;
			set-position(absolute, { top: 12px, right: -11px });
			set-transform($translate.rotate_counterclockwise_90);
			set-font($montserrat, 10px, 700, 40px, $black);
			font-tracking(100);
		}
	}
}

/*   MEDIA QUERIES   */

+set-breakpoint(min_width_414)
	.header {
		.top,
		.dealer {
			padding: 0 25px;
		}

		.dealer {
			a {
				font-size: 12px;
			}
		}
	}

+set-breakpoint(min_width_768)
	.header {
		height: 80px;

		.top {
			padding: 0 25px;
			height: 80px;
			font-size: 16px;
		}
	}

+set-breakpoint(min_width_980)
	.header {
		display: block;
		height: 100%;
		pointer-events: none;
		top: 100vh;
		visibility: visible;

		.top {
			justify-content: center;
			padding: 0;
			width: 100%;
			height: 80px;
			font-size: 18px;

			br {
				display: none;
			}
		}

		.dealer {
			padding: 0;
			width: 40px;
			height: 120px;
			background-color: transparent;
			set-position(absolute, { top: 50%, left: 30px });
			set-transform($translate.center);

			a {
				border-bottom: 2px solid $cold_turkey_dark;
				set-position(absolute, { top: 39px, right: -34px });
				set-transform($translate.rotate_clockwise_90);
				set-font($montserrat, 12px, 700, 40px, $cold_turkey_dark);
			}
		}

		.back {
			display: block;
		}
	}

+set-breakpoint(min_width_1200)
	.header {
		.dealer {
			left: 55px;
		}

		.back {
			right: 55px;
		}
	}