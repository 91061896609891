/*              */
/*   WEBFONTS   */
/*              */

@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		 url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Montserrat-Regular.woff2') format('woff2'),
		 url('fonts/Montserrat-Regular.woff') format('woff'),
		 url('fonts/Montserrat-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		 url('fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Montserrat-Bold.woff2') format('woff2'),
		 url('fonts/Montserrat-Bold.woff') format('woff'),
		 url('fonts/Montserrat-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'GTSectraFine';
	src: url('fonts/GTSectraFine-Bold.eot');
	src: local('GTSectraFine Bold'), local('GTSectraFine-Bold'),
		 url('fonts/GTSectraFine-Bold.eot?#iefix') format('embedded-opentype'),
		 url('fonts/GTSectraFine-Bold.woff2') format('woff2'),
		 url('fonts/GTSectraFine-Bold.woff') format('woff'),
		 url('fonts/GTSectraFine-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: bold;
}

/*           */
/*   TOOLS   */
/*          */

@import 'core/mixins'

/*               */
/*   VARIABLES   */
/*               */

$gt_sectra_fine = "GTSectraFine", "Times New Roman", serif;
$montserrat = "Montserrat", "Verdana", sans-serif;

$black = #000;

$grey_15 = #151515;
$grey_44 = #444;
$grey_88 = #888;
$grey_b6 = #B6B6B6;
$grey_bf = #BFBFBF;
$grey_f5 = #F5F5F5;

$white = #FFF;

$cold_turkey_light = #CCB9B3;
$cold_turkey_dark = #B8A7A1;

$sprite = {
	path: url("images/sprite.svg"),
	width: 60px,
	height: 20px
}

$index = {
	spec: 999,
	hero: 998,
	header: 997
};

$translate = {
	center_x: translate3d(-50%, 0, 0),
	rotate_clockwise_90: rotate(-90deg),
	rotate_counterclockwise_90: rotate(90deg)
};

$breakpoints = {
	min_width_375: "only screen and (min-width: 375px)", // iPhone 6 and bigger
	min_width_414: "only screen and (min-width: 414px)", // iPhone 6 Plus and bigger
	min_width_480: "only screen and (min-width: 480px)", // iPhone 6 Plus and bigger
	min_width_768: "only screen and (min-width: 768px)", // iPad and bigger
	min_width_980: "only screen and (min-width: 980px)", // small desktop and bigger
	min_width_1200: "only screen and (min-width: 1200px)", // large desktop and bigger
	min_width_1440: "only screen and (min-width: 1440px)", // Macbook and bigger
	min_width_1800: "only screen and (min-width: 1800px)", // Huge
	min_height_414: "only screen and (min-height: 414px)" // iPhone 6 Plus landscape and bigger
}

/*                */
/*   ANIMATIONS   */
/*                */

@keyframes imageZoom {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

@keyframes headerPinned {
	from {
		set-transform($translate.top);
		visibility: visible;
	}

	to {
		set-transform($translate.none);
	}
}

@keyframes headerUnpinned {
	from {
		set-transform($translate.none);
	}

	to {
		visibility: hidden;
		set-transform($translate.top);
	}
}

/*            */
/*   IMPORT   */
/*            */

@import 'core/foundation'
@import 'partials/**/*'