/*          */
/*   SPEC   */
/*          */

.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	visibility: hidden;
	set-position(fixed, { top: 0, left: 0, right: 0, bottom: 0, z-index: $index.spec });

	h2 {
		padding: 10px 0;
		text-transform: uppercase;
		set-font($gt_sectra_fine, 21px, 700, 28px, $grey_44);
		font-tracking(240);
	}

	small {
		display: block;
		padding: 25px 0 5px 0;
		text-transform: uppercase;
		set-font($montserrat, 11px, 700, 22px, $grey_b6);
		font-tracking(100);
	}

	span {
		display: inline-block;
		text-transform: uppercase;
		set-font($gt_sectra_fine, 16px, 700, 20px, $grey_44);
		font-tracking(240);
	}

	iframe {
		width: 100%;
		height: 100%;
		set-position(absolute, { top: 0, left: 0 });
	}

	&-panel {
		overflow: scroll;
		padding: 70px 30px 30px 30px;
		width: 100%;
		max-width: 1280px;
		max-height: 100%;
		background-color: $white;
		box-sizing: border-box;
		opacity: 0;
		z-index: 2;
		will-change: opacity;
		set-position(relative);
		set-transition opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

		.close {
			display: block;
			set-position(absolute, { top: 25px, right: 25px, z-index: 2 });
			set-sprite-clip(-20px, 0, 20px, 20px);
		}

		&.dark {
			background-color: $grey_44;

			.close {
				set-sprite-clip(-40px, 0, 20px, 20px);
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			set-position(relative);
		}

		.column {
			&-left {
				z-index: 2;
			}

			&-right {
				flex: 1;
				height: 100%;
			}
		}

		.graphic {
			margin-top: 40px;
			width: 100%;
			height: 240px;
			background: url("images/spec.jpg") no-repeat center center;
			background-size: cover;
		}

		.video {
			padding-bottom: 56.25%;
			width: 100%;
			set-position(relative);
		}
	}

	&-overlay {
		background-color: alpha($grey_44, 0.9);
		opacity: 0;
		will-change: opacity;
		set-position(fixed, { top: 0, left: 0, right: 0, bottom: 0 });
		set-transition opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	&.fullscreen {
		padding: 0;

		.modal-panel {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 70px 25px;
			width: 100vw;
			height: 100vh;
		}
	}

	&.open {
		visibility: visible;
	}

	&.animate {
		.modal-overlay {
			opacity: 1;
		}

		.modal-panel {
			opacity: 1;
		}
	}
}

/*   MEDIA QUERIES   */

+set-breakpoint(min_width_375)
	.modal {
		padding: 20px;

		&.fullscreen {
			padding: 0;
		}
	}

+set-breakpoint(min_width_480)
	.modal {
		&.fullscreen {
			padding: 0;

			.modal-panel {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 70px 80px;
				width: 100vw;
				height: 100vh;
			}
		}
	}

+set-breakpoint(min_width_768)
	.modal {
		padding: 30px;

		h2 {
			font-size: 27px;
			line-height: 36px;
		}

		&-panel {
			padding: 70px 80px;
		}

		&.fullscreen {
			padding: 30px;

			.modal-panel {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 70px 80px;
				width: 100%;
				max-width: 1280px;
				max-height: 100%;
			}
		}
	}

+set-breakpoint(min_width_980)
	.modal {
		&-panel {
			.content {
				flex-direction: row;
			}

			.column {
				&-left {
					min-width: 340px;
					max-width: 340px;
				}

				&-right {
					align-self: flex-end;
				}
			}

			.graphic {
				margin-top: 0;
				width: auto;
				height: 100%;
				max-height: 410px;
				set-position(absolute, { top: 70px, left: 420px, right: 80px });
			}
		}
	}

+set-breakpoint(min_width_1200)
	.modal {
		padding: 40px;

		h2 {
			font-size: 36px;
			line-height: 48px;
		}
	}