/*            */
/*   FOOTER   */
/*            */

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 20px;
	width: 100%;
	background-color: $white;
	text-transform: uppercase;
	text-align: center;
	box-sizing: border-box;
	set-font($montserrat, 11px, 400, normal, $grey_44);
	font-tracking(100);

	p {
		padding-bottom: 30px;
		set-font($montserrat, 12px, 400, 18px, $grey_88);
		font-tracking(240);
	}

	a {
		color: $black;
	}
}

/*   MEDIA QUERIES   */

+set-breakpoint(min_width_768)
	.footer {
		padding: 60px 0 30px 0;

		p {
			padding-bottom: 50px;
			font-size: 14px;
		}

		br {
			display: none;
		}
	}

+set-breakpoint(min_width_980)
	.footer {
		background-color: $grey_f5;
	}