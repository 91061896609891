/*            */
/*   BUTTON   */
/*            */

.button {
	display: inline-block;
	padding: 0 48px;
	background-color: transparent;
	border: 1px solid $grey_44;
	box-sizing: border-box;
	white-space: nowrap;
	will-change: background-color;
	set-font($montserrat, 13px, 400, 48px, $grey_44);
	font-tracking(100);
	set-transition opacity 300ms ease;

	&:hover,
	&:active {
		opacity: 0.5;
	}
}