/*                */
/*   FOUNDATION   */
/*                */

html {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	background-color: $white;
	//set-font($roboto, 16px, 300, 24px, $grey_default);
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;

	&.no-scroll {
		overflow: hidden;
	}
}

h1,
h2,
ul,
p {
	margin: 0;
	padding: 0;
}

li {
	display: block;
}

h1,
h2 {
	font-size: inherit;
	font-weight: normal;
	font-style: normal;
}
/*
h2 {
	set-font($roboto, 24px, 400, 30px, $grey_dark);
}

p {
	padding: 20px 0 0 0;

	&:first-of-type
	{
		padding: 0;
	}
}

small {
	font-size: 14px;
	line-height: 21px;
}

strong {
	font-weight: 700;
}
*/

a {
	text-decoration: none;
	cursor: pointer;
}

*:focus {
    outline: none;
}

/*   MEDIA QUERIES   */

+set-breakpoint(min_width_768)
	//...

+set-breakpoint(min_width_1200)
	//...