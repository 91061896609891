/*          */
/*   HOME   */
/*          */

.home {
	&-hero {
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		width: 100vw;
		height: 100vh;
		box-sizing: border-box;
		text-align: center;
		z-index: $index.hero;
		set-position(relative);

		h1 {
			padding: 44px 0 30px 0;
			text-transform: uppercase;
			set-font($gt_sectra_fine, 27px, 700, 30px, $grey_15);
			font-tracking(240);
		}

		p {
			margin: 0 auto 50px auto;
			padding: 0 30px;
			max-width: 340px;
			text-transform: uppercase;
			set-font($montserrat, 12px, 400, 22px, $grey_15);
			font-tracking(600);
		}

		.content {
			z-index: 2;
		}

		.bg {
			width: 100%;
			height: 100%;
			animation: imageZoom ease-out 60s;
			animation-iteration-count: infinite;
			set-position(absolute, { top: 0, left: 0, z-index: 1 });

			&-wrapper {
				height: 100%;
				background: url("images/home-hero-bg.jpg") no-repeat left top;
				background-size: auto 100%;
				visibility: hidden;
			}
		}

		.more {
			display: none;
			width: 50px;
			height: 56px;
			set-position(absolute, { bottom: 20px, left: 50%, z-index: 2 });
			set-transform($translate.center_x);

			a {
				display: block;
				set-position(absolute, { top: 20px, left: 20px });
				set-sprite-clip(0, 0, 10px, 16px);
			}
		}
	}

	&-block {
		padding: 60px 0 0 0;

		h2 {
			text-transform: uppercase;
			set-font($gt_sectra_fine, 21px, 700, 28px, $black);
			font-tracking(240);
		}

		p {
			padding-top: 25px;
			set-font($montserrat, 12px, 400, 24px, $black);
			font-tracking(80);
		}

		small {
			text-transform: uppercase;
			set-font($montserrat, 8px, 400, normal, $grey_bf);
			font-tracking(600);
		}

		a {
			display: inline-block;
			text-transform: uppercase;
			set-font($montserrat, 11px, 700, normal, $grey_b6);
			font-tracking(100);
		}

		img {
			display: block;
		}

		&-1,
		&-2,
		&-3,
		&-3b,
		&-4,
		&-5,
		&-5b,
		&-6 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			set-position(relative);

			.border {
				width: 15px;
				background-color: $cold_turkey_light;
				set-position(absolute, { top: 0, left: 0, bottom: 0, z-index: 2 });
			}

			.content {
				box-sizing: border-box;
				text-align: left;
				z-index: 2;
				set-position(relative);
			}

			.graphic {
				flex: 1;
				z-index: 2;

				&-wrapper {
					width: 100%;
					height: 100%;
					will-change: opacity;
					set-transition opacity 300ms linear;
				}
			}

			.bg {
				display: flex;
				width: 100%;
				set-position(absolute, { top: 0, left: 50%, bottom: 0, z-index: 1 });
				set-transform($translate.center_x);
			}
		}

		&-1 {
			.border {
				margin: 25px 0;
			}

			.content {
				padding: 0 50px;
			}

			.graphic {
				padding: 60px 0 0 50px;
				height: 240px;
				visibility: hidden;

				&-wrapper {
					background: url("images/home-block-1-bg.jpg") no-repeat left center;
					background-size: auto 100%;
				}
			}
		}

		&-2 {
			margin-top: 60px;
			background-color: $grey_44;

			.content {
				padding: 480px 40px 60px 40px;
				text-align: center;

				h2,
				p {
					color: $white;
				}

				h2 {
					padding-top: 25px;
				}
			}

			.graphic {
				display: none;
			}

			.bg {
				height: 420px;

				&-wrapper {
					width: 100%;
					background: url("images/home-block-2-bg.png") no-repeat center bottom;
					background-size: auto 100%;
					visibility: hidden;
				}
			}
		}

		&-3 {
			align-items: center;
			justify-content: flex-start;
			height: 1216px;

			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 60px 0;
				width: 100%;
				max-width: 1440px;

				&-left {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 30px;
					width: 100%;
					height: 636px;
					box-sizing: border-box;
				}

				&-right {
					margin-top: 60px;
					padding: 40px 40px 0 40px;
				}
			}

			.mosaic {
				width: 100%;
				set-position(absolute, { top: 0, left: 0, z-index: 1 });

				&-left,
				&-right {
					overflow: hidden;
					width: 15px;
					height: 476px;
					set-position(absolute, { top: 140px });
				}

				&-left {
					left: 0;
				}

				&-right {
					right: 0;
				}

				&-center {
					overflow: hidden;
					margin: 60px 0;
					max-width: 1440px;
					height: 1100px;
					box-sizing: border-box;
					set-position(relative);

					&-1,
					&-2 {
						overflow: hidden;
						margin-left: -(180px / 2)px;
						width: 180px;
						height: 238px;
						set-position(absolute, { left: 50% });
					}

					&-1 {
						top: 0;
					}

					&-2 {
						top: 398px;
					}

					&-3 {
						overflow: hidden;
						width: 50%;
						height: 400px;
						set-position(absolute, { top: 696px, right: 70% });
					}
				}

				&-image {
					visibility: hidden;
				}
			}

			p {
				&:first-of-type {
					padding: 0;
				}
			}
		}

		&-3b {
			.graphic {
				height: 240px;
				visibility: hidden;

				&.left {
					padding: 0 50px 30px 0;

					.graphic-wrapper {
						width: auto;
						height: 100%;
						background: url("images/home-block-3b-left-bg.jpg") no-repeat right center;
						background-size: auto 100%;
					}
				}

				&.right {
					padding: 30px 0 60px 0;

					.graphic-wrapper {
						width: auto;
						height: 100%;
						background: url("images/home-block-3b-right-bg.jpg") no-repeat center center;
						background-size: auto 100%;
					}
				}
			}
		}

		&-4 {
			padding: 100px 0;
			background-color: $grey_44;

			.graphic {
				visibility: hidden;

				&-wrapper {
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				img {
					width: auto;
					height: 360px;
				}
			}

			.bg {
				align-items: flex-end;

				&-wrapper {
					width: 100%;
					height: 100%;
					background: url("images/home-block-4-bg.jpg") no-repeat center center;
					background-size: cover;
					visibility: hidden;
				}
			}
		}

		&-5 {
			margin-top: 60px;

			.content {
				padding: 25px 50px;
			}

			.graphic {
				padding: 60px 0 0 0;
				height: 240px;
				visibility: hidden;

				&-wrapper {
					background: url("images/home-block-5-bg.jpg") no-repeat center center;
					background-size: auto 100%;
				}
			}
		}

		&-5b {
			flex-direction: column-reverse;
			margin-top: 100px;

			.graphic {
				height: 240px;
				visibility: hidden;

				&.left {
					padding: 30px 0 60px 0;

					.graphic-wrapper {
						width: auto;
						height: 100%;
						background: url("images/home-block-5b-left-bg.jpg") no-repeat center center;
						background-size: auto 100%;
					}
				}

				&.right {
					padding: 0 0 30px 50px;

					.graphic-wrapper {
						width: auto;
						height: 100%;
						background: url("images/home-block-5b-right-bg.jpg") no-repeat left center;
						background-size: auto 100%;
					}
				}
			}
		}

		&-6 {
			.border {
				display: none;
				left: auto;
				right: 0;
				margin: 50px 0;
			}

			.content {
				padding: 80px 40px 360px 40px;
			}

			.graphic {
				display: none;
			}

			.bg {
				overflow: hidden;
				align-items: flex-end;
				height: 100%;
				background-color: $grey_f5;

				&-wrapper {
					width: 100%;
					height: 360px;
					background: url("images/home-block-6-bg.jpg") no-repeat center bottom;
					background-size: auto 100%;
					visibility: hidden;
				}
			}
		}
	}

	&-bga {
		padding: 60px 30px 30px 30px;
		set-font($montserrat, 12px, 400, 18px, $grey_88);
		font-tracking(80);
		text-align: center;

		img {
			display: block;
			margin: 30px auto 0 auto;
		}
	}
}

/*   MEDIA QUERIES   */

/*
@media
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
}
*/

+set-breakpoint(min_width_414)
	.home {
		&-block {
			&-1,
			&-3b,
			&-5b {
				.graphic {
					height: 320px;
				}
			}
		}
	}

+set-breakpoint(min_width_768)
	.home {
		&-hero {
			h1 {
				font-size: 36px;
				line-height: 20px;

				br {
					display: none;
				}
			}

			p {
				padding: 0;
			}

			.more {
				bottom: 60px;
			}
		}

		&-block {
			padding: 80px 0 0 0;

			h2 {
				font-size: 27px;
				line-height: 36px;
			}

			p {
				font-size: 13px;
				line-height: 28px;
			}

			small {
				br {
					display: none;
				}
			}

			&-1,
			&-2,
			&-3,
			&-3b,
			&-4,
			&-5,
			&-5b,
			&-6 {
				.border {
					width: 25px;
				}
			}

			&-1 {
				.content {
					padding: 0 100px;
				}

				.graphic {
					padding: 80px 0 0 100px;
					height: 420px;

					&-wrapper {
						background-size: cover;
					}
				}
			}

			&-2 {
				margin-top: 80px;

				.content {
					padding: 500px 100px 80px 100px;
				}

				.graphic {
					display: block;
				}
			}

			&-3 {
				.content {
					&-right {
						padding: 80px 100px;
					}
				}

				.mosaic {
					&-left,
					&-right {
						width: 30px;
					}

					&-center {
						&-1,
						&-2 {
							margin-left: -(264px / 2)px;
							width: 265px;
						}
					}
				}
			}

			&-3b {
				.graphic {
					height: 420px;

					&.left {
						padding: 20px 100px 40px 0;

						.graphic-wrapper {
							background-size: cover;
						}
					}

					&.right {
						padding: 40px 0 80px 0;
					}
				}
			}

			&-4 {
				padding: 200px 0;
			}

			&-5 {
				margin-top: 100px;

				.content {
					padding: 25px 100px;
				}

				.graphic {
					padding: 80px 0 0 0;
				}
			}

			&-5b {
				.graphic {
					height: 420px;

					&.right {
						padding: 20px 0 40px 100px;

						.graphic-wrapper {
							background-size: cover;
						}
					}

					&.left {
						padding: 40px 0 80px 0;
					}
				}
			}

			&-6 {
				.content {
					padding: 80px 100px 480px 100px;
				}

				.bg {
					&-wrapper {
						min-width: 100%;
						background-size: 100% auto;
					}
				}
			}
		}
	}

+set-breakpoint(min_width_980)
	.home {
		&-hero {
			z-index: 1;
		}

		&-block {
			padding: 120px 0;

			p {
				&.spec {
					text-align: right;
				}
			}

			&-1,
			&-2,
			&-3,
			&-3b,
			&-4,
			&-5,
			&-5b,
			&-6 {
				flex-direction: row;

				.content {
					min-width: 50%;
					max-width: 50%;
					set-position(static);
				}

				.graphic {
					max-width: 50%;
				}
			}

			&-1 {
				.border {
					margin: 50px 0;
				}

				.content {
					padding: 50px 80px 50px 100px;
				}

				.graphic {
					padding: 0;
					height: auto;

					&-wrapper {
						background-size: auto 100%;
					}
				}
			}

			&-2 {
				flex-direction: row-reverse;

				.content {
					padding: 100px 100px 100px 80px;
				}

				.bg {
					max-width: 1440px;
					height: auto;

					&-wrapper {
						width: 66%;
						background: url("images/home-block-2-bg.png") no-repeat right center;
						background-size: cover;
					}
				}
			}

			&-3 {
				height: 890px;

				.content {
					flex-direction: row;
					margin: 0;
					min-width: 100%;
					max-width: 100%;

					&-left,
					&-right {
						width: 50%;
						box-sizing: border-box;
					}

					&-left {
						justify-content: flex-start;
						padding: 0 80px 0 100px;
					}

					&-right {
						margin-top: 0;
						padding: 0 100px 0 80px;
					}
				}

				.mosaic {
					&-left,
					&-right {
						margin-top: -(584 / 2)px;
						//width: 30px;
						height: 584px;
						set-position(absolute, { top: 50% });
					}

					&-left {
						left: 0;
					}

					&-right {
						right: 0;
					}

					&-center {
						margin: 60px 260px 60px 0;
						height: 770px;

						&-1,
						&-2 {
							margin: 0;
							width: 265px;
							height: 238px;
							set-position(absolute, { left: 100px });
						}

						&-1 {
							top: 0;
						}

						&-2 {
							top: auto;
							bottom: 0;
						}

						&-3 {
							margin-top: -(584 / 2)px;
							width: 424px;
							height: 584px;
							set-position(absolute, { top: 50%, left: 435px, right: auto });
						}
					}
				}
			}

			&-3b {
				padding: 80px 0;

				.graphic {
					height: 460px;
					box-sizing: border-box;

					&.left {
						padding: 0;
					}

					&.right {
						padding: 0 80px 0 100px;

						.graphic-wrapper {
							background: url("images/home-block-3b-right-bg.jpg") no-repeat left center;
						}
					}
				}
			}

			&-4 {
				padding: 100px;

				.graphic {
					img {
						height: auto;
						min-height: 100%;
					}
				}
			}

			&-5 {
				.content {
					padding: 50px 80px 50px 100px;
				}

				.graphic {
					padding: 0;
					height: auto;

					&-wrapper {
						background: url("images/home-block-5-bg.jpg") no-repeat left center;
					}
				}
			}

			&-5b {
				padding: 80px 0;

				.graphic {
					height: 520px;
					box-sizing: border-box;

					&.left {
						padding: 0 100px 0 80px;

						.graphic-wrapper {
							background: url("images/home-block-5b-left-bg.jpg") no-repeat center center;
						}
					}

					&.right {
						padding: 0;
					}
				}
			}

			&-6 {
				flex-direction: row-reverse;

				.border {
					display: block;
				}

				.content {
					padding: 120px 100px 120px 80px;
					text-align: left;
				}

				.graphic {
					display: block;
				}

				.bg {
					align-items: flex-start;
					max-width: 1440px;
					height: auto;
					background-color: $white;

					&-wrapper {
						width: 64%;
						min-width: auto;
						height: 100%;
						background: url("images/home-block-6-bg.jpg") no-repeat right center;
						background-size: auto 100%;
					}
				}
			}
		}

		&-bga {
			padding: 0 0 60px 0;
		}
	}

+set-breakpoint(min_width_1200)
	.home {
		&-block {
			h2 {
				font-size: 36px;
				line-height: 48px;
			}

			p {
				padding-top: 50px;
			}

			&-1 {
				.content {
					padding: 50px 120px 50px 150px;
					min-width: 670px;
					max-width: 720px;
				}

				.graphic {
					max-width: 720px;
				}
			}

			&-2 {
				margin-top: 120px;

				.content {
					padding: 100px 150px 100px 120px;
					min-width: 670px;
					max-width: 720px;

					h2 {
						padding-top: 50px;
					}
				}

				.graphic {
					max-width: 720px;
				}
			}

			&-3 {
				.content {
					&-left,
					&-right {
						max-width: 720px;
					}

					&-left {
						padding: 0 0 0 150px;
					}

					&-right {
						padding: 0 150px 0 80px;
					}
				}

				.mosaic {
					&-left,
					&-right {
						width: 60px;
					}

					&-center {
						margin: 60px 320px 60px 0;

						&-1,
						&-2 {
							left: 200px;
						}

						&-3 {
							left: 605px;
						}
					}
				}
			}

			&-3b {
				padding: 80px 0;

				.graphic {
					&.left {
						max-width: 720px;
					}

					&.right {
						padding: 0 120px 0 150px;
					}
				}
			}

			&-4 {
				padding: 200px 0;
			}

			&-5 {
				margin-top: 150px;

				.content {
					padding: 50px 120px 50px 150px;
					min-width: 670px;
					max-width: 720px;
				}

				.graphic {
					max-width: 720px;
				}
			}

			&-5b {
				padding: 80px 0;

				.graphic {
					&.right {
						max-width: 720px;
					}

					&.left {
						padding: 0 150px 0 120px;
					}
				}
			}

			&-6 {
				margin-top: 120px;

				.content {
					padding: 180px 150px 180px 120px;
					min-width: 670px;
					max-width: 720px;
				}

				.graphic {
					max-width: 720px;
				}
			}
		}
	}

+set-breakpoint(min_width_1440)
	.home {
		&-hero {
			.bg {
				&-wrapper {
					background-size: cover;
				}
			}
		}

		&-block {
			&-3 {
				.mosaic {
					&-center {
						margin: 60px auto;
						width: 100%;
					}
				}
			}

			&-3b,
			&-5b {
				margin: 60px auto;
				max-width: 1440px;
			}
		}
	}

+set-breakpoint(min_width_1800)
	.home {
		&-block {
			&-1 {
				.content {
					padding: 50px 120px 50px 0px;
				}
			}
		}
	}

+set-breakpoint(min_height_414)
	.home {
		&-hero {
			.more {
				display: block;
			}
		}
	}