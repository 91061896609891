/*            */
/*   MIXINS   */
/*            */

/*
 * VENDOR
 *
 * @property {string} Property name
 * @value {string} Property value
 */

vendor(property, value)
	-webkit-{property} value
	-moz-{property} value
	{property} value

/*
 * SET POSITION
 *
 * @position {string} Position type
 * @options {object} Keys/values list of properties (z-index, top, left, right, bottom)
 */

set-position(position, options = {})
	position: position;

	for key, value in options
		{key} value

/*
 * SET FONT
 *
 * @family {string} Font family
 * @size {integer} Font size
 * @weight {integer} Font weight
 * @height {integer} Line height
 * @color {hex} Color
 * @style {string} Font style
 */

set-font(family, size, weight, height, color, style = false)
	font-family: family;
	font-size: size;
	font-weight: weight;
	line-height: height;
	color: color;

	if style
		font-style: style;

/*
 * FONT TRACKING
 *
 * @tracking {integer} Font tracking
 */

font-tracking(val, base = false)
	if base
		letter-spacing: ((val * base) / 1000) px
	else
		letter-spacing: (val / 1000) em

/*
 * SET SPRITE CLIP
 *
 * @x {string} X-axis position
 * @y {integer} Y-axis position
 * @w {integer} Icon width
 * @h {integer} Icon height
 * @scale {integer} Sprite scale factor
 */

set-sprite-clip(x, y, w = false, h = false, scale = 1)
	background-image: $sprite.path;
	background-repeat: no-repeat;
	background-size: ($sprite.width * scale) ($sprite.height * scale);
	background-position: (x * scale) (y * scale);

	if w
		width: w * scale;

	if h
		height: h * scale;

/*
 * SET TRANSITION
 *
 * @arguments {string} Transition value
 */

set-transition()
	vendor(transition, arguments)

/*
 * SET TRANSFORM
 *
 * @arguments {string} Transform value
 */

set-transform()
	vendor(transform, arguments)

/*
 * SET COLUMN BREAK INSIDE
 *
 * @position {string} Break type
 */

set-column-break-inside(value)
	-webkit-column-break-inside: value;
	page-break-inside: value;
	break-inside: value;

/*
 * SET PROPERTY
 *
 * @property {string} Property name
 * @value {string} Property value
 */

set-property(property, value)
	vendor(property, value)

/*
 * SET BREAKPOINT
 *
 * @breakpoint {string} Breakpoint name
 */

set-breakpoint(breakpoint)
	@media $breakpoints[breakpoint]
		{block}